import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { dummy, colourOptions, options } from "./Index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import JoditEditior from "../../../shared/Editor/JoditEditior";
import { Button } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../shared/constants";

const JobCreateModel = (props) => {
  const [editorContent, setEditorContent] = useState("");
  const [data, setData] = useState({}); // Holds job data
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  // // Fetch data based on ID
  // useEffect(() => {
  //     if (id) {
  //         const userData = dummy.find((user) => user.id === parseInt(id));
  //         setData(userData);
  //     }
  // }, [id]);
  //
  // // Handler for input and select changes

  // {
  //     id: 1,
  //         date: "2024-09-30",
  //     name: "Jane Smith",
  //     email: "janesmith@example.com",
  //     mobile: "+0987654321",
  //     jobTitle: "Data Scientist",
  //     resume: "Link to Jane's resume",
  //     city: "New Delhi",
  //     department: "IT Services",
  //     noticePeriod: "30",
  //     coverLetter:
  //     "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters...",
  // }

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/jobs-form-data`);
        console.log("get form res->", response.data);
        if (response.data && !response.data.error) {
          const options = response.data.data[0];
          setQualificationOptions(options.qualificationOptions);
          setCategoryOptions(options.categoryOptions);
          setLocationOptions(options.locationOptions);
          setTagOptions(options.tagOptions);
          setJobTypeOptions(options.jobTypeOptions);
          console.log(options)
        } else {
          alert("Error fetching form data");
        }
      } catch (error) {
        console.error("Error fetching options:", error);
        alert("Failed to load form data.");
      }
    };
    fetchOptions();
  }, []);

  const submitForm = () => {
    const payload = {
      category: data?.category?.value,
      jobTitle: data?.jobTitle,
      jobType: data?.jobType?.value,
      jobDescription: editorContent,
      experience: data?.experience,
      qualification: data?.qualification?.value,
      preferredLocation: data?.preferredLocation?.value,
      tags: data?.tags?.value,
    };
    console.log("cnt in create", editorContent);
    console.log("data in ", data);
    console.log("payoad in create", payload);
    axios
      .post(
        `${BASE_URL}/jobs`,
        { data: payload }
        //
        //     headers: {
        //         Authorization: `Bearer ${token}`,
        //     },
        // }
      )
      .then((res) => {
        console.log(res.data.data);
        props.onClose();
      })
      .catch((err) => {
        alert("Error creating Job Create Job");
      });
  };
  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e?.target?.checked ?? e;
      setData((prevState) => ({ ...prevState, [name]: value }));
    },
    [setData]
  );
  //
  // if (!data) {
  //     return <div>Loading...</div>; // Shows a loading state when data is not available yet
  // }

  return (
    <div
      className="blog-container"
      style={{ overflow: "initial", padding: "30px", background: "#fff" }}
    >
      <div className="blog-header-container">
        <span className="blog-header">
          <ArrowBackIosIcon className="back_button" onClick={props.onClose} />
          Create Job
        </span>
      </div>

      <div className="form_o_cb">
        <div className="form_row">
          {/* Job Title */}
          <div className="form_col_6 form_container_box">
            <label>Job Title</label>
            <input
              type="text"
              className="set_height"
              placeholder="Job Title"
              autoComplete="off"
              value={data.jobTitle || ""}
              onChange={handleChange("jobTitle")} // Binding state
            />
          </div>

          {/* Job Type */}
          <div className="form_col_6 form_container_box">
            <label>Job Type</label>
            <Select
              options={jobTypeOptions}
              value={jobTypeOptions?.find((opt) => opt.value === data.jobType)} // Binding selected value
              onChange={handleChange("jobType")} // Updates state when selecting job type
            />
          </div>

          {/* Category */}
          <div className="form_col_6 form_container_box">
            <label>Departments</label>
            <Select
              options={categoryOptions}
              value={categoryOptions?.find((opt) => opt.value === data.category)} // Binding selected value
              onChange={handleChange("category")} // Updates state when selecting job type
            />
          </div>

          {/* Tags */}
          <div className="form_col_6 form_container_box">
            <label>Tags</label>
            <Select
              // defaultValue={colourOptions.filter(
              //   (option) => ["tag1", "tag2"].includes(option.value) // Replace with dynamic tag list
              // )}
              // isMulti
              value={tagOptions?.find((opt) => opt.value === data.tags)}
              // name="colors"
              options={tagOptions}
              classNamePrefix="select"
              onChange={handleChange("tags")} // Handling tags selection
            />
          </div>

          {/* Qualification */}
          <div className="form_col_6 form_container_box">
            <label>Qualification</label>
            <Select
              options={qualificationOptions}
              value={qualificationOptions?.find((opt) => opt.value === data.qualification)} // Binding selected value
              onChange={handleChange("qualification")} // Updates state when selecting job type
            />
          </div>

          {/* Job for preferred Location */}
          <div className="form_col_6 form_container_box">
            <label>Job for Preferred Location</label>
            <div className="set_height">
              <Select
                options={locationOptions}
                value={locationOptions?.find(
                  (opt) => opt.value === data.preferredLocation
                )}
                onChange={handleChange("preferredLocation")} // Binding state
              />
            </div>
          </div>

          {/* Experience */}
          <div className="form_col_6 form_container_box">
            <label>Experience</label>
            <input
              type="number"
              className="set_height"
              placeholder="Experience in years"
              autoComplete="off"
              value={data.experience || ""}
              onChange={handleChange("experience")} // Binding state
            />
          </div>
        </div>

        {/* Job Description */}
        <div className="form_comp">
          <label htmlFor="title">Job Description</label>
          <JoditEditior
            content={editorContent || ""}
            setContent={(content) => {
              console.log("content", content);
              setEditorContent(content);
            }}
            config={{
              minHeight: 600,
            }}
            // Handling editor changes
          />
        </div>

        <div className="btn_o_c">
          <Button
            variant="contained"
            className="bordered-button"
            onClick={submitForm}
          >
            Create
          </Button>
          <Button
            variant="contained"
            className="bordered-button"
            onClick={props.onClose}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JobCreateModel;
