import React, { useEffect } from "react";
import "./performance-report.css";
import { Button, InputLabel, FormControl, TextField,TablePagination,CircularProgress } from "@mui/material";
import axios from "axios";
import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { BASE_URL } from "../../shared/constants";

const PerformanceReport = () => {
  let [data, setData] = React.useState([]);
  let [searchData, setSearchData] = React.useState([]);
  let [text, setText] = React.useState("");
  const [exportLoading, setExportLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20); // Default to 5 rows per page
  const [totalCount, setTotalCount] = React.useState(0); // Default to 5 rows per page

  useEffect(() => {
    getdetails();
  }, []);

  // Fetch data when page or rowsPerPage change
  useEffect(() => {
    getdetails();
  }, [page, rowsPerPage]);

  const getdetails = async () => {
    const res = await axios.get(`${BASE_URL}/user/interim-storage?text=${text}&page=${page+1}&limit=${rowsPerPage}`, {});
    setData(res?.data?.data || []);
    setSearchData(res?.data?.data || []);
    setTotalCount(res?.data?.total_count || 0);
  };

  const exportData = async () => {
    setExportLoading(true);
    const res = await axios.get(`${BASE_URL}/user/interim-storage?text=${text}&page=${1}&limit=${10000}`, {});
    setExportLoading(false);
    const xlsData = res?.data?.data || [];
    const worksheet = XLSX.utils.json_to_sheet(xlsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(
      blob,
      "Interim Storage " + moment.utc().format("DD-MMM-YYYY HH:MM") + ".xlsx"
    );
  };

  const filterData = async () => {
    const res = await axios.get(
      `${BASE_URL}/user/interim-storage?text=${text}&page=${1}&limit=${rowsPerPage}`,
      {}
    );
    setPage(0);
    setSearchData(res?.data?.data || []);
    setTotalCount(res?.data?.total_count || 0);
  };

  const handleFilter = (e) => {
    setText(e.target.value);
    if (e.target.value === "") {
      setSearchData(data);
    }
  };

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page changes
  };



  return (
    <div className="pr-m-wrapper">
      <div className="pr-header-container">
        <span className="pr-header">Interim Storage</span>
        <div className="btn-container">
        {exportLoading ?<CircularProgress/> : 
        <Button
        onClick={exportData}
        variant="contained"
        className="bordered-button"
      >
        Export Data
      </Button>
        }
          
        </div>
      </div>

      <div className="filter-cb">
        <FormControl
          sx={{
            minWidth: 200,
          }}
          size="small"
        >
          <TextField
            id="outlined-basic"
            label="Filter Data"
            variant="outlined"
            className="input-set"
            onChange={handleFilter}
          />
        </FormControl>

        <Button
          onClick={filterData}
          variant="contained"
          className="bordered-button"
        >
          Filter
        </Button>
      </div>

      <div className="pr-outer-table">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">City</th>
              <th scope="col">Bike Model</th>
              <th scope="col">Full Name</th>
              <th scope="col">Mobile Number</th>
              <th scope="col">Email ID</th>
              <th scope="col">Showroom</th>
              <th scope="col">Whatsapp Number</th>
              <th scope="col">OTP Verified</th>
            </tr>
          </thead>
          <tbody>
            {searchData?.map((o, i) => (
              <tr key={i}>
                <td>
                  {o?.SlotTime}
                </td>
                <td>{o.City}</td>
                <td>{o?.BikeName}</td>
                <td>{o?.FirstName + " " + o?.LastName}</td>
                <td>{o?.Mobile}</td>
                <td>{o?.Email}</td>
                <td>{o?.ShowroomName}</td>
                <td>{o?.WhatsAppNumber}</td>
                <td>{o?.Is_Mobile_Verified_c ? "Yes" : "No"}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <TablePagination
      rowsPerPageOptions={[20,50,100]}
      component="div"
      count={totalCount}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
      </div>
    </div>
  );
};

export default PerformanceReport;