import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../shared/constants";

const JobsFormData = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/jobs-form-data`);
        setData(response.data.data[0]); // Access the first object in the data array
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <h1>Jobs Form Data</h1>

      <h2>Job Options</h2>
      <ul>
        {data?.jobOptions?.map((job) => (
          <li key={job.value}>{job.label}</li>
        ))}
      </ul>

      <h2>Category Options</h2>
      <ul>
        {data?.categoryOptions?.map((category) => (
          <li key={category.value}>{category.label}</li>
        ))}
      </ul>

      <h2>Location Options</h2>
      <ul>
        {data?.locationOptions?.map((location) => (
          <li key={location.value}>{location.label}</li>
        ))}
      </ul>

      <h2>Tag Options</h2>
      <ul>
        {data?.tagOptions?.map((tag) => (
          <li key={tag.value}>{tag.label}</li>
        ))}
      </ul>

      <h2>Job Type Options</h2>
      <ul>
        {data?.jobTypeOptions?.map((jobType) => (
          <li key={jobType.value}>{jobType.label}</li>
        ))}
      </ul>

      <Link to="/dashboard/form/edit" state={{ data }}>
        Edit Form
      </Link>
    </div>
  );
};

export default JobsFormData;
