import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { dummy, colourOptions, options } from "./Index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import JoditEditior from "../../../shared/Editor/JoditEditior";
import axios from "axios";
import { BASE_URL } from "../../../shared/constants";
import { Button } from "@mui/material";
import Loader from "../../../shared/Loader";

const JobDetail = () => {
  const [data, setData] = useState(null); // Holds job data
  const [editorContent, setEditorContent] = useState(
    data?.jobDescription || ""
  );
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/jobs-form-data`);
        console.log("get form res->", response.data);
        if (response.data && !response.data.error) {
          const options = response.data.data[0];
          setQualificationOptions(options.qualificationOptions);
          setCategoryOptions(options.categoryOptions);
          setLocationOptions(options.locationOptions);
          setTagOptions(options.tagOptions);
          setJobTypeOptions(options.jobTypeOptions);
          console.log(options)
        } else {
          alert("Error fetching form data");
        }
      } catch (error) {
        console.error("Error fetching options:", error);
        alert("Failed to load form data.");
      }
    };
    fetchOptions();
  }, []);

  // Fetch data based on ID
  useEffect(() => {
    if (id) {
      getdetails();
    }
  }, [id]);

  const token = localStorage.getItem("obenev-token");

  const getdetails = async () => {
    const res = await axios.get(`${BASE_URL}/jobs/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const payload = {
      category: res.data?.data?.category,
      jobTitle: res.data?.data?.job_title,
      jobType: res.data?.data?.job_type,
      jobDescription: res.data?.data?.job_description,
      experience: res.data?.data?.experience,
      qualification: res.data?.data?.qualification,
      preferredLocation: res.data?.data?.location,
      tags: res.data?.data?.tags?.[0],
    };
    setData(payload);
  };

  // Handler for input and select changes
  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e?.target?.checked ?? e;
      setData((prevState) => ({ ...prevState, [name]: value }));
    },
    [setData]
  );

  if (!data) {
    return (
      <div className="loading_cb">
        <Loader />
      </div>
    ); // Shows a loading state when data is not available yet
  }

  const submitForm = () => {
    console.log(editorContent)
    const payload = {
      category: data?.category?.value || data?.category,
      jobTitle: data?.jobTitle?.value || data?.jobTitle,
      jobType: data?.jobType?.value || data?.jobType,
      jobDescription: editorContent || data.jobDescription,
      experience: data?.experience,
      qualification: data?.qualification?.value || data?.qualification,
      preferredLocation:
        data?.preferredLocation?.value || data?.preferredLocation,
      tags: data?.tags?.value || data?.tags,
    };
    axios
      .put(
        `${BASE_URL}/jobs/${id}`,
        { data: payload }
        //
        //     headers: {
        //         Authorization: `Bearer ${token}`,
        //     },
        // }
      )
      .then((res) => {
        console.log(res.data.data);
        navigate(-1);
      })
      .catch((err) => {
        alert("Error updating Job Create Job");
      });
  };
  console.log("data in edit", data);

  return (
    <div className="blogs-container">
      <div className="blog-container" style={{ overflow: "initial" }}>
        <div className="blog-header-container">
          <span className="blog-header">
            <ArrowBackIosIcon
              className="back_button"
              onClick={() => navigate(-1)}
            />
            Job Detail
          </span>
        </div>

        <div className="form_o_cb">
          <div className="form_row">
            {/* Job Title */}
            <div className="form_col_6 form_container_box">
              <label>Job Title</label>
              <input
                type="text"
                className="set_height"
                placeholder="Job Title"
                autoComplete="off"
                value={data.jobTitle || ""}
                onChange={handleChange("jobTitle")} // Binding state
              />
            </div>

            {/* Job Type */}
            <div className="form_col_6 form_container_box">
              <label>Job Type</label>
              <Select
                  options={jobTypeOptions}
                value={jobTypeOptions?.find((opt) => opt.value === data.jobType)} // Binding selected value
                onChange={handleChange("jobType")} // Updates state when selecting job type
              />
            </div>

            {/* Category */}
            <div className="form_col_6 form_container_box">
              <label>Departments</label>
              <Select
                options={categoryOptions}
                value={categoryOptions?.find((opt) => opt.value === data.category)} // Binding selected value
                onChange={handleChange("category")} // Updates state when selecting job type
              />
            </div>

            {/* Tags */}
            <div className="form_col_6 form_container_box">
              <label>Tags</label>
              <Select value={tagOptions?.find((opt) => opt.value === data.tags)}
                // isMulti
                name="colors"
                options={tagOptions}
                classNamePrefix="select"
                onChange={handleChange("tags")} // Handling tags selection
              />
            </div>

            {/* Qualification */}
            <div className="form_col_6 form_container_box">
              <label>Qualification</label>
              <Select
                options={qualificationOptions}
                value={qualificationOptions?.find((opt) => opt.value === data.qualification)} // Binding selected value
                onChange={handleChange("qualification")} // Updates state when selecting job type
              />
            </div>

            {/* Job for preferred Location */}
            <div className="form_col_6 form_container_box">
              <label>Job for Preferred Location</label>
              <div className="set_height">
                <Select
                  options={locationOptions}
                  value={locationOptions?.find(
                    (opt) => opt.value === data.preferredLocation
                  )}
                  onChange={handleChange("preferredLocation")} // Binding state
                />
              </div>
            </div>

            {/* Experience */}
            <div className="form_col_6 form_container_box">
              <label>Experience</label>
              <input
                type="number"
                className="set_height"
                placeholder="Experience in years"
                autoComplete="off"
                value={data.experience || ""}
                onChange={handleChange("experience")} // Binding state
              />
            </div>
          </div>

          {/* Job Description */}
          <div className="form_comp">
            <label htmlFor="title">Job Description</label>
            <JoditEditior
              content={data.jobDescription || ""}
              // onChange={handleChange("jobDescription")}
              setContent={(content) => {
                setEditorContent(content);
              }}
              config={{
                minHeight: 600,
              }}
              // Handling editor changes
            />
          </div>
          <div className="btn_o_c">
            {" "}
            <Button
              variant="contained"
              className="bordered-button"
              onClick={submitForm}
            >
              Update
            </Button>
            <Button
              variant="contained"
              className="bordered-button"
              onClick={() => navigate(-1)}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetail;
