import React, { useEffect, useState } from "react";

import {
  Container,
  TextField,
  Typography,
  Button,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { adminLoginService } from "./service";
import { adminInfoStorageService } from "../../shared/services";

import "./style.css";
import { getSchema } from "./utils";

const Login = () => {
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(getSchema()),
  });
  const navigate = useNavigate();

  const handleLogin = async (admin) => {
    const { data, error } = await adminLoginService(admin);

    if (data) {

      adminInfoStorageService(data);

      if(data["user_type"] === "ADMIN"){
        navigate("/dashboard/blog");
      }else if(data["user_type"] === "HR"){
        navigate("/hr-portal/candidate-listing");
      }
      return;
    }
    if (error) {
      console.log(errors);
      setError(error);
      setTimeout(() => {
        setError("");
      }, 2000);
    }
  };
  useEffect(() => {
    localStorage.removeItem("obenev-token");
  }, []);
  return (
    <Container className="login-container">
      <Typography className="login-header">ObenEv</Typography>
      <form onSubmit={handleSubmit(handleLogin)}>
        <TextField
          {...register("email")}
          label="Email"
          fullWidth
          variant="outlined"
          error={errors.email}
          helperText={errors.email?.message}
        />
        <TextField
          {...register("password")}
          label="Password"
          type="password"
          fullWidth
          variant="outlined"
          error={errors.password}
          helperText={errors.password?.message}
        />
        <Button fullWidth variant="contained" type="submit">
          Login
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        severity="error"
        autoHideDuration={2000}
      />
    </Container>
  );
};

export default Login;
