import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dummy } from "./Index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import {BASE_URL} from "../../../shared/constants";
import moment from "moment";

const CandidateDetail = () => {
  const [data, setData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getdetails();
    }
  }, [id]);

  const token = localStorage.getItem("obenev-token");

  const getdetails = async () => {
    const res = await axios.get(`${BASE_URL}/candidate/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("res.data.data ::",res.data.data);
    setData(res.data.data);
  };
  if (!data) {
    return <div>Loading...</div>; // Optionally handle case when data is not loaded yet
  }

  const {
    name,
    email,
    mobile,
    jobTitle,
    city,
    department,
    noticePeriod,
    coverLetter,
    date,
    meta,
    resume,
    location,
    qualification,
    candType,
    experience,
    currentCompany,
    currentDesignation,
    
  } = data;

  return (
    <div className="blogs-container">
      <div className="blog-container">
        <div className="blog-header-container">
          <span className="blog-header">
            {" "}
            <ArrowBackIosIcon
              className="back_button"
              onClick={() => navigate(-1)}
            />
            Candidate Detail - {name}
          </span>
        </div>

        <div className="cb-o-cb">
          {name && (
            <div className="i-cb">
              <strong>Name :</strong>
              <span>{name}</span>
            </div>
          )}

          {email && (
            <div className="i-cb">
              <strong>Email Address :</strong>
              <span>{email}</span>
            </div>
          )}

          {mobile && (
            <div className="i-cb">
              <strong>Mobile :</strong>
              <span>{mobile}</span>
            </div>
          )}

          {jobTitle && (
            <div className="i-cb">
              <strong>Job Title :</strong>
              <span>{jobTitle}</span>
            </div>
          )}

          {city && (
            <div className="i-cb">
              <strong>City :</strong>
              <span>{city}</span>
            </div>
          )}

          {location && (
            <div className="i-cb">
              <strong>Location :</strong>
              <span>{location}</span>
            </div>
          )}


          {qualification && (
            <div className="i-cb">
              <strong>Qualification :</strong>
              <span>{qualification}</span>
            </div>
          )}


          {department && (
            <div className="i-cb">
              <strong>Department :</strong>
              <span>{department}</span>
            </div>
          )}

          {candType && (
            <div className="i-cb">
              <strong>Experince :</strong>
              <span>{candType}</span>
            </div>
          )}

        {experience && (
            <div className="i-cb">
              <strong>Yrs of Experince :</strong>
              <span>{experience}</span>
            </div>
          )}

          {currentCompany && (
            <div className="i-cb">
              <strong>Current Company :</strong>
              <span>{currentCompany}</span>
            </div>
          )}

          {currentDesignation && (
            <div className="i-cb">
              <strong>Current Designation :</strong>
              <span>{currentDesignation}</span>
            </div>
          )}


          {noticePeriod && (
            <div className="i-cb">
              <strong>Notice Period :</strong>
              <span>{noticePeriod} Days</span>
            </div>
          )}

        {meta.job_details && meta.job_details.job_title && (
            <div className="i-cb">
              <strong>Applied For :</strong>
             <a href={`/hr-portal/job-detail/${meta?.job_details?._id}`}> <span>{meta?.job_details?.job_title}</span></a>
            </div>
          )}

          {date && (
            <div className="i-cb">
              <strong>Applied Date :</strong>
              <span>{moment(date).format("DD-MMM-YYYY")}</span>
            </div>
          )}

          {coverLetter && (
            <div className="i-cb full-w-cb">
              <strong>Cover Letter :</strong>
              <span>{coverLetter}</span>
            </div>
          )}

          {resume && (
            <div className="i-cb full-w-cb">
              <strong>Resume :</strong>
              <a href={resume} target="_blank" rel="noopener">{resume}</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateDetail;
